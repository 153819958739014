import React, { useState } from "react";
import Layout from "../../layout";
import api from "../../services/api";
import { navigate, Link } from "gatsby";
import isEmail from "validator/lib/isEmail";
import { toast } from "react-toastify";

const devValue = (value, falseValue = "") => {
  return process.env.NODE_ENV === "development" ? value : falseValue;
};

// markup
const PasswordResetPage = () => {
  const parsed = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : ""
  );
  const [progress, setProgress] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("success");

  const [email, setEmail] = useState(devValue("mahesh@owita.lk"));

  const handleSubmit = (e) => {
    e.preventDefault();
    setProgress(true);
    setMessage(false);
    try {
      api("post", "reset-password", { email }).then((res) => {
        setProgress(false);
        if (res.data.status && res.data.message) {
          setType("success");
          setMessage("Please check your email for the instructions.");
        }
      });
    } catch (error) {
      setProgress(false);
      setType("danger");
      setMessage(
        "Error occurred while login. If this error persists, please contact system administrator."
      );
    }
  };

  return (
    <Layout
      title={"Password Reset"}
      description={"Forgot your password? Enter your email to reset it."}
      publicPage
    >
      <section className="login py-8 py-lg-16">
        <article className="form-wrapper ms-auto me-auto">
          <Verify email={parsed.get("token")} token={parsed.get("verify")} />
        </article>
      </section>
    </Layout>
  );
};

export default PasswordResetPage;

const Verify = ({ email, token }) => {
  const [progress, setProgress] = useState(false);
  const [message, setMessage] = useState(false);

  const [password, setPassword] = useState(devValue("password"));
  const [confirmPassword, setConfirmPassword] = useState(devValue("password"));

  const handleLogin = (e) => {
    e.preventDefault();
    const data = {
      password,
      hashedEmail: email,
      token,
    };
    setMessage(null);
    setProgress(true);

    try {
      api("post", "reset-password", data).then((res) => {
        setProgress(false);
        if (res.data.status === "error") {
          // setMessage(res.data.message);
          toast.error(res.data.message);
        } else if (res.data.status === "success") {
          toast.success("Success. Please Login to continue.");
          navigate("/user/login?status=pw-reset-success");
        }
      });
    } catch (error) {
      setProgress(false);
      setMessage(
        "Error occurred while login. If this error persists, please contact system administrator."
      );
      console.log(error);
    }
  };

  const disabled = progress || !password || password !== confirmPassword;

  return (
    <form onSubmit={handleLogin}>
      {message && (
        <p className="uk-text-danger uk-display-block uk-text-center">
          {message}
        </p>
      )}
      <div className="uk-margin">
        <label htmlFor="password">Password</label>
        <div className="uk-inline uk-width-1-1">
          <span
            className="uk-form-icon uk-form-icon-flip"
            data-uk-icon="icon: lock"
          />
          <input
            id={"password"}
            className="form-control"
            type="password"
            placeholder="Your password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="my-4">
        <label htmlFor="confirm_password">Confirm Password</label>
        <div className="uk-inline uk-width-1-1">
          <span
            className="uk-form-icon uk-form-icon-flip"
            data-uk-icon="icon: lock"
          />
          <input
            className="form-control"
            type="password"
            id={"confirm_password"}
            placeholder="Confirm your password"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="uk-margin uk-text-center">
        <button
          type="submit"
          disabled={disabled || progress}
          className="btn btn-primary rounded-pill mb-4 ms-auto me-auto text-center d-block w-100"
        >
          Reset Password
        </button>
      </div>
      <div className="uk-margin uk-text-center">
        <Link to="/user/login">Back to Login</Link>
      </div>
    </form>
  );
};
